import '../styles/main.css';
import Lottie from "lottie-react";
import MusicPlaying from '../assets/images/music-play.json';
import MusicStop from '../assets/images/music-stop.json';

export const MusicPlayer = (props) => {
    return(
        <div className="music-wrapper" onClick={props.onClick}>
            {props.isPlaying ? 
                <Lottie animationData={MusicPlaying} style={{width: 50}}/>
            : 
                <Lottie animationData={MusicStop}/>
            }
            
        </div>
    );
}