
import '../styles/main.css';
import '../styles/styles.css';
import '../styles/welcome-modal.css';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css";
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

const AutoplaySlider = withAutoplay(AwesomeSlider);

export const CoupleIdentity = () => {
    return(
        <div className='py-8'>
            <div className='mt-lg'/>
                <ScrollAnimation duration={2} animateIn='fadeInUp' animateOnce={true}>
                    <span className='font-gv text-primary' style={{fontSize: '24pt', marginTop: '4rem'}}>Om Swastyastu</span>
                </ScrollAnimation>
                <div className='mt-md'/>
                <ScrollAnimation duration={2} delay={2} animateIn='fadeInUp' animateOnce={true}>
                    <div className='text-normal text-primary mx-6 mb-10' style={{lineHeight: '1.2'}}>Atas Asung Kertha Wara Nugraha Ida Sang Hyang Widhi Wasa/Tuhan Yang Maha Esa kami bermaksud mengundang Bapak/Ibu/Saudara/i pada Upacara Manusa Yadnya Pawiwahan (Pernikahan) kami.</div>
                </ScrollAnimation>

                <div className='flex h-[60vh]'>
                <ScrollAnimation duration={2} animateIn='fadeInLeft' animateOnce={true} className='w-[80px] flex justify-center items-center transform font-gv text-primary text-[50px]'>
                    <div className='rotate-180' style={{ writingMode: 'vertical-rl' }}>
                        Mangwi
                    </div>
                </ScrollAnimation>

                <ScrollAnimation duration={2} animateIn='fadeInRight' animateOnce={true} className='relative flex w-[100%] h-[100%]'>
                    <div className='flex w-[100%] h-[100%] relative transform rotate-180'>
                        <AutoplaySlider
                            play={true}
                            cancelOnInteraction={true} // should stop playing on user interaction
                            interval={3000}
                            bullets={false}
                            disabled={false}
                        >
                            <div className='bg-[yellow] h-[100%] w-[100%] male-photo1 text-[transparent] rotate-180'>A</div>
                            <div className='bg-[blue] h-[100%] w-[100%] male-photo2 text-[transparent] rotate-180'>A</div>
                        </AutoplaySlider>
                    </div>
                    <div className='absolute w-full h-full'></div>
                    <div className='bg-[rgba(255,255,255,0.6)] w-[100%] p-[2] text-left p-2 absolute bottom-0'>
                        <div className='subtitle text-[18px] text-primary font-bold'>I Nyoman Wiratma Jaya, S.Kom</div>
                        <div className='text-[16px]'>Anak ketiga dari Bapak I Wayan Wirta dan Ibu Ni Ketut Asih</div>
                    </div>
                </ScrollAnimation>
            </div>
            {/* female */}
            <div className='flex w-full h-[60vh] mt-8'>
                <ScrollAnimation duration={2} animateIn='fadeInRight' animateOnce={true} className='flex w-[100%] h-[100%] relative'>
                    <AutoplaySlider
                        play={true}
                        cancelOnInteraction={false} // should stop playing on user interaction
                        interval={3000}
                        bullets={false}
                    >
                        <div className='bg-[yellow] h-[100%] w-[100%] female-photo1 text-[transparent]'>A</div>
                        <div className='bg-[blue] h-[100%] w-[100%] female-photo2 text-[transparent]'>A</div>
                    </AutoplaySlider>
                    <div className='absolute w-full h-full'></div>
                    <div className='bg-[rgba(255,255,255,0.6)] w-[100%] p-[2] text-left p-2 absolute bottom-0 z-50'>
                        <div className='subtitle text-[18px] text-primary font-bold'>Ni Made Widyantari, S.Pd</div>
                        <div className='text-[16px]'>Anak kedua dari Bapak I Wayan Budiarta dan Ibu Ni Wayan Yastari</div>
                    </div>
                </ScrollAnimation>
                <ScrollAnimation duration={2} animateIn='fadeInRight' animateOnce={true}  className='w-[80px] flex justify-center items-center rotate-90 font-gv text-primary text-[50px]'>
                    <div className='transform rotate-240' style={{ writingMode: 'vertical-rl' }}>
                        Tari
                    </div>
                </ScrollAnimation>
            </div>
        </div>  
    );
}