import '../styles/main.css';
import '../styles/styles.css';
import '../styles/welcome-modal.css';
import 'react-photo-view/dist/react-photo-view.css';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css";
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

const AutoplaySlider = withAutoplay(AwesomeSlider);

export const Moment = () => {
    return(
       <div>
        <ScrollAnimation duration={2} animateIn='fadeInUp' animateOnce={true}>
            <div className='font-gv text-primary mb-md' style={{fontSize: '24pt'}}>Momen Bahagia</div>
        </ScrollAnimation>
        <div className='flex w-full bg-primary relative'>
            <div className='h-full w-[80%] mt-[3px]'>
                <AutoplaySlider
                    play={true}
                    cancelOnInteraction={false} // should stop playing on user interaction
                    interval={3000}
                    bullets={false}
                    disabled={true}
                >
                    <div className='h-[100%] w-[100%] gallery-slide-image-1 p-1 text-[transparent]'>
                        <div className='border-2 border-[white] h-[100%] w-[100%] p-[2px]'>
                            <div className='border-2 border-[white] h-[100%] w-[100%]'></div>
                        </div>
                    </div>
                    <div className='h-[100%] w-[100%] gallery-slide-image-2 p-1 text-[transparent]'>
                        <div className='border-2 border-[white] h-[100%] w-[100%] p-[2px]'>
                            <div className='border-2 border-[white] h-[100%] w-[100%]'></div>
                        </div>
                    </div>
                </AutoplaySlider>

            </div>
            <div className=' flex w-[20%] h-[100%] items-center justify-center'>
                <div className='w-[100%] h-[24vh] floral-1 floral-1 mt-10'/>
            </div>
        </div>

        <div className='josefin-sans text-[14px] text-left p-3'>
        A successful marriage requires falling in love many times, always with the same person.
        </div>

        <div className='flex w-full bg-primary items-center'>
            <div className='flex w-[20%] h-[100%] items-center justify-center'>
                <div className='w-[100%] h-[24vh] floral-1 mt-10'/>
            </div>

            <div className='h-full w-[80%] rotate-180'>
                <AutoplaySlider
                    play={true}
                    cancelOnInteraction={false} // should stop playing on user interaction
                    interval={3000}
                    bullets={false}
                >
                    <div className='h-[100%] w-[100%] gallery-slide-image-3 p-1 text-[transparent] rotate-180'>
                        <div className='border-2 border-[white] h-[100%] w-[100%] p-[2px]'>
                            <div className='border-2 border-[white] h-[100%] w-[100%]'></div>
                        </div>
                    </div>
                    <div className='h-[100%] w-[100%] gallery-slide-image-4 p-1 text-[transparent] rotate-180'>
                        <div className='border-2 border-[white] h-[100%] w-[100%] p-[2px]'>
                            <div className='border-2 border-[white] h-[100%] w-[100%]'></div>
                        </div>
                    </div>
                </AutoplaySlider>
            </div>
        </div>
        <div className='josefin-sans text-[14px] text-right p-3'>
        True love stands by each other's side on good days and stands closer on bad days.
        </div>

        <div className='flex w-full bg-primary'>
            <div className='h-full w-[80%] mt-[3px]'>
                <AutoplaySlider
                    play={true}
                    cancelOnInteraction={false} // should stop playing on user interaction
                    interval={3000}
                    bullets={false}
                >
                    <div className='h-[100%] w-[100%] gallery-slide-image-5 p-1 text-[transparent]'>
                        <div className='border-2 border-[white] h-[100%] w-[100%] p-[2px]'>
                            <div className='border-2 border-[white] h-[100%] w-[100%]'></div>
                        </div>
                    </div>
                    <div className='h-[100%] w-[100%] gallery-slide-image-6 p-1 text-[transparent]'>
                        <div className='border-2 border-[white] h-[100%] w-[100%] p-[2px]'>
                            <div className='border-2 border-[white] h-[100%] w-[100%]'></div>
                        </div>
                    </div>
                </AutoplaySlider>
            </div>
            <div className=' flex w-[20%] h-[100%] items-center justify-center'>
                <div className='w-[100%] h-[24vh] floral-1 mt-10'/>
            </div>
        </div>
        <div className='josefin-sans text-[14px] text-left p-3'>
        Real love stories never have endings but continue to grow and flourish over time.
        </div>

        {/* <ScrollAnimation duration={2} delay={2} animateIn='fadeInUp' animateOnce={true}>
            <div className='text-normal text-primary mt-md mb-lg' style={{lineHeight: 1.5, marginRight: '1rem', marginLeft: '1rem'}}>
            "Menciptakan kenangan adalah hadiah yang tak ternilai harganya. Kenangan akan bertahan seumur hidup; benda-benda hanya dalam waktu singkat."
            </div
        </ScrollAnimation>
           <PhotoProvider>
               {images.map((item)=>{
                   return(
                    <div key={item.image1} className='photo-wrapper' style={{marginTop: '1%'}}>
                        <ScrollAnimation style={{width: '100%', display: 'flex', marginLeft: '1%'}} duration={2} delay={4} animateIn='fadeInUp' animateOnce={true}>
                            <PhotoView src={item.image1}>
                                <img src={item.thubnail1} className="image-gallery"  alt="" />
                            </PhotoView>
                        </ScrollAnimation>
                        <ScrollAnimation style={{width: '100%', display: 'flex'}} duration={2} delay={6} animateIn='fadeInUp' animateOnce={true}>
                            <PhotoView src={item.image2}>
                                <img src={item.thubnail2} className="image-gallery"  alt="" />
                            </PhotoView>
                        </ScrollAnimation>
                    </div>
                   );
               })}
            </PhotoProvider> */}
       </div>
    );
}