import React, { useEffect, useState } from 'react';
import '../styles/styles.css';
import heart from './../assets/images/heart.svg';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css";
import { db } from './../config/firebase';
import { onValue, ref, push } from 'firebase/database';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const mm = [
    '',
    'Januari',
    'Februari',
    'Maret',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Agustus',
    'September',
    'Oktober',
    'November',
    'Desember',
  ];
export const PesanKesan =()=>{
    const [nama, setNama] = useState('');
    const [alamat, setAlamat] = useState('');
    const [ucapan, setUcapan] = useState('');
    const [list, setList] = useState([]);

    useEffect(()=>{
        const query = ref(db, "/ucapan")
        return onValue(query, (snapshot) => {
          const data = snapshot.val();
          if (snapshot.exists()) {
            setList(Object.values(data).map((ucapan) => ({
              ...ucapan
            })))
          }
        });
    },[]);

    const _save = () => {
        if (!nama || !alamat || !ucapan) {
            toast('Pastikan Anda telah mengisi data!')
            return;
        }
        const query = ref(db, "/ucapan")
        push(query, {
            nama: nama,
            alamat: alamat,
            ucapan: ucapan,
            time: new Date().getTime()
        });
        toast('Ucapamnu berhasil disimpan')
        setNama('');
        setAlamat('');
        setUcapan('');
    }

    const onClick = () => {
        const url = 'https://wa.me/6285156621870'
        window.open(url, "_blank")
    }

    return(
        <div className='sambutan-container'>
            <ScrollAnimation duration={2} animateIn='fadeInUp' animateOnce={true}>
            <div className='font-gv text-primary mb-md' style={{fontSize: '24pt'}}>Pesan & Kesan</div>
            </ScrollAnimation>  
            
            <ScrollAnimation duration={2} delay={2} animateIn='fadeInUp' animateOnce={true}>
                <div className='flex flex-col px-4 items-start text-normal'>
                    <span>Nama</span>
                    <input onChange={(e)=>setNama(e.target.value)} value={nama} placeholder='John Doe' className='border-[1px] border-primary w-full h-[36px] rounded-md px-3'/>
                </div>
            </ScrollAnimation>

            <ScrollAnimation duration={2} delay={4} animateIn='fadeInUp' animateOnce={true}>
                <div className='flex flex-col px-4 my-3 items-start text-normal'>
                    <span>Alamat</span>
                    <input onChange={(e)=>setAlamat(e.target.value)} value={alamat} placeholder='Denpasar' className='border-[1px] border-primary w-full h-[36px] rounded-md px-3'/>
                </div>
            </ScrollAnimation>

            <ScrollAnimation duration={2} delay={6} animateIn='fadeInUp' animateOnce={true}>
                <div className='flex flex-col px-4 items-start text-normal mb-6'>
                    <span>Ucapan</span>
                    <textarea placeholder='Kasi ucapan kamu di sini...' onChange={(e)=>setUcapan(e.target.value)} value={ucapan} className="border-[1px] border-primary w-full h-[100px] rounded-md p-3"></textarea>
                </div>
            </ScrollAnimation>

            <ScrollAnimation duration={2} delay={8} animateIn='fadeInUp' animateOnce={true}>
                <div onClick={_save} className="custom-button bg-primary bold-text">
                    Kirim Ucapan
                </div>
            </ScrollAnimation>
            <div className='mt-lg'/>
            <div className='flex flex-col px-2'>
                {list.map((item)=>{
                    return(
                        <UcapanBubble 
                            key={item.time}
                            item={item}
                        />
                    );
                })}
            </div>
            
            <ScrollAnimation duration={2} delay={14} offset={10} animateIn='fadeInUp' animateOnce={true}>
                <div className='w-full h-[80vh] thanks-image relative'>
                    <div className='w-[100%] h-[100%] absolute bg-[rgba(0,0,0,0.5)] flex flex-col items-center justify-between pt-[10vh] p-4'>
                        <div>
                            <ScrollAnimation duration={2} animateIn='fadeInUp' animateOnce={true}>
                                <span className='text-[white]' style={{fontSize: '12pt', marginTop: '4rem'}}>Terima kasih atas perhatian dan Doa Bapak/Ibu/saudara/i</span>
                            </ScrollAnimation>
                            <div className='mt-8'/>
                            <ScrollAnimation duration={2} animateIn='fadeInUp' animateOnce={true}>
                                <span className='font-gv text-primary text-[white]' style={{fontSize: '24pt', color: 'white'}}>Om Santih, Santih, Santih Om</span>
                            </ScrollAnimation>
                        </div>
                        <div className='absoulte b-0'>
                            <ScrollAnimation duration={2} delay={14} offset={10} animateIn='fadeInUp' animateOnce={true}>
                                <div className='text-normal mt-lg text-[white]'>Made with ❤️ 2024</div>
                                <div className='text-normal text-[white]'>Want to make something like this?</div>
                                <div onClick={onClick} className="open-invitation-btn my-2 text-bold animate__animated animate__fadeInLeft">
                                    Click here
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </ScrollAnimation>
            <ToastContainer />
        </div>
    );
}

const _parseDate = (time) => {
    let date = new Date(time);
    var tanggal = (date.getDate() < 10 ? '0' : '') + date.getDate(),
        bulan = (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1),
        tahun = date.getFullYear();
    
    var hours = (date.getHours() < 10 ? '0' : '') + date.getHours(),
        minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
    
    return (
        tanggal + ' ' + mm[parseInt(bulan)] + ' ' + tahun + ` ${hours}:${minutes}`
    );
}

const UcapanBubble = (props) => {
    return(
        <ScrollAnimation duration={2} delay={10} animateIn='fadeInUp' animateOnce={true}>
        <div className="ucapan-container mt-md w-full border-primary">
            <div className="ucapan-header">
                <div className="ucapan-profile">
                    <img src={heart} className="heart-icon"/>
                </div>
                <div className="name-header">
                    <span className="text-bold text-normal">
                        {props.item.nama}
                    </span>
                    <span className="bold-text gray-text text-normal">
                        {props.item.alamat}
                    </span>
                </div>
            </div>
            <div className="regular-text ucapan-content text-normal">
                {props.item.ucapan}
                <br/>
                <span style={{fontSize: '10pt', marginTop: '10px'}} className="bold-text gray-text text-normal">
                {_parseDate(props.item.time)}
                </span>
            </div>
        </div>
        </ScrollAnimation>
    );
}