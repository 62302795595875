import '../styles/main.css';
import '../styles/styles.css';
import '../styles/welcome-modal.css';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css";
import { useEffect, useState } from 'react';
import 'react-awesome-slider/dist/styles.css';
import ReactPlayer from 'react-player'
import first_video from '../assets/video/teaser-tamblingan.mp4';
import second_video from '../assets/video/teaser-tengenan.mp4';


export const Gallery = (props) => {
    const [images, setImages] = useState([]);
    const [player1, setPlayer1] = useState(false);
    const [player2, setPlayer2] = useState(false);

    useEffect(()=>{
        const galeri = [];
        for(let i = 1; i < 28; i++) {
            galeri.push({
                image1: require(`../assets/images/galeri/${i}.jpg`),
                thubnail1: require(`../assets/images/galeri/thubnail/${i}.jpg`),
                image2: require(`../assets/images/galeri/${i+1}.jpg`),
                thubnail2: require(`../assets/images/galeri/thubnail/${i+1}.jpg`)
            });
        }
        setImages(galeri);
    },[]);

    useEffect(()=>{
        if(player1 || player2) {
            props.setMusicPlayer(false);
        }
        if(player1 && player2) {
            props.setMusicPlayer(false);
        }
        if(!player1 && !player2) {
            props.setMusicPlayer(true);
        }
    },[player1, player2]);

    const opts = {
        height: '230',
        width: '100%',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 0,
        //   controls: 0
        },
    };

    return(
       <div className='mt-3'>
        <ScrollAnimation duration={2} animateIn='fadeInUp' animateOnce={true}>
            <div className='font-gv text-primary mb-md' style={{fontSize: '24pt'}}>Our Gallery</div>
        </ScrollAnimation>

        <ScrollAnimation duration={2} delay={2} animateIn='fadeInUp' animateOnce={true}>
            <div className='text-normal text-primary mt-md mb-md' style={{lineHeight: 1.5, marginRight: '1rem', marginLeft: '1rem'}}>
            "our journey"
            </div>
        </ScrollAnimation>
        
        <ScrollAnimation duration={2} delay={2} animateIn='fadeInUp' animateOnce={true}>
            <div className='px-3'>
                <div className='w-full rounded-lg bg-[black]'>
                    {/* <YouTube 
                        videoId="B3G9fmOSct0" 
                        opts={opts} 
                        iframeClassName="rounded-lg"
                        onPlay={()=>setPlayer1(true)} 
                        onPause={()=>setPlayer1(false)} 
                        onEnded={()=>setPlayer1(false)}
                        onStart={()=>setPlayer1(true)}
                    /> */}
                    {/* <video onPlay={onPlay} onPause={onPaused} onStop={onStop} width="100%" height="500" className='rounded-lg' controls>
                        <source src={first_video} type="video/mp4"/>
                    </video> */}
                    <ReactPlayer 
                        key={'3323'}
                        className="rounded-lg overflow-hidden" 
                        url={first_video} 
                        width="100" 
                        height="500" 
                        onError={(e)=>console.log(e)}
                        controls={true}
                        onPlay={()=>setPlayer1(true)} 
                        onPause={()=>setPlayer1(false)} 
                        onEnded={()=>setPlayer1(false)}
                        onStart={()=>setPlayer1(true)}
                    />
                </div>
            </div>
        </ScrollAnimation>
        <ScrollAnimation duration={2} delay={4} animateIn='fadeInUp' animateOnce={true}>
            <div className='px-3 mt-3'>
            <div className='w-full rounded-lg bg-[black]'>
                    {/* <YouTube 
                        videoId="spgAGjEBeIk" 
                        opts={opts} 
                        iframeClassName="rounded-lg"
                        onPlay={()=>setPlayer2(true)} 
                        onPause={()=>setPlayer2(false)} 
                        onEnded={()=>setPlayer2(false)}
                        onStart={()=>setPlayer2(true)}
                    /> */}
                    {/* <video onPlay={onPlay} onPause={onPaused} onStop={onStop} width="100%" height="500" className='rounded-lg' controls>
                        <source src={second_video} type="video/mp4"/>
                    </video> */}
                    <ReactPlayer 
                        key={'12321'}
                        className="rounded-lg overflow-hidden" 
                        url={second_video} 
                        width="100" 
                        height="500" 
                        onError={(e)=>console.log(e)}
                        controls={true}
                        onPlay={()=>setPlayer2(true)} 
                        onPause={()=>setPlayer2(false)} 
                        onEnded={()=>setPlayer2(false)}
                        onStart={()=>setPlayer2(true)}
                    />
                </div>
            </div>
        </ScrollAnimation>
        <PhotoProvider>
            <div className='flex flex-wrap p-3'>
               {images.map((item, index)=>{
                   if (index == 2 || index == 5 || index == 8 || index == 11 || index == 14 || index == 17 || index == 20 || index == 23 || index == 26) {
                    return(
                        <ScrollAnimation key={item.thubnail1} duration={2} delay={4} style={{width: '100%'}} animateIn='fadeInUp' animateOnce={true}>
                            <PhotoView src={item.image1}>
                                <img src={item.thubnail1} className="w-[100%] h-[25vh] object-cover mb-2 rounded-xl" style={{marginRight: index%2 == 0 ? '0.75rem' : '0px'}} alt="" />
                            </PhotoView>
                        </ScrollAnimation>
                       );
                   }
                   return(
                    <>
                    <ScrollAnimation duration={2} delay={4} style={{width: '49%'}} animateIn='fadeInUp' animateOnce={true}>
                        <PhotoView src={item.image1}>
                            <img src={item.thubnail1} className="w-[100%] h-[25vh] object-cover mb-2 rounded-xl" alt="" />
                        </PhotoView>
                     </ScrollAnimation>
                     <div className='w-[2%]'></div>
                     </>
                   );
               })}
            </div>
        </PhotoProvider>
       </div>
    );
}