import '../styles/main.css';
import '../styles/styles.css';
import '../styles/welcome-modal.css';
import { Counter } from './Counter';
import { CoupleIdentity } from './CoupleIdentity';
import { Gallery } from './Gallery';
import { Mantra } from './Mantra';
import { Moment } from './Moment';
import { PesanKesan } from './PesanKesan';
import { Quote } from './Quote';
import { Reseption } from './Reseption';
import { Slider } from './Slider';

export const InvitationPage = (props) => {

    return(
        <>
            <Slider />
            <div className='divider'/>
            <Mantra />
            <div className='divider'/>
            <Quote />
            <CoupleIdentity />  
            <Reseption />  
            <Counter />   
            <Moment />
            <Gallery setMusicPlayer={props.setMusicPlayer} isPlaying={props.isPlaying}/>
            <div className='divider'/>
            <PesanKesan />
        </>
    );
}